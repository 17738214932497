import { robotPrefix } from ".";
import { apiUrl, _axios_base_get_list } from "..";

const PAGESIZE = 30;

export default function getProfileDailyProfit(
  limit = PAGESIZE,
  skip = 0,
  query = {}
) {
  return _axios_base_get_list(
    `${apiUrl}/${robotPrefix}/getProfileDailyProfit`,
    limit,
    skip,
    query
  );
}
